import React, { useContext, useEffect, useState } from 'react';
import { Tab, Nav, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DashboardCoinChart from '../newcomponents/CoinChart';
import CoinBuyOrderTable from '../newcomponents/CoinBuyOrderTable';
import CoinSellOrderTable from '../newcomponents/CoinSellOrderTable';
import BalanceTicker from '../newcomponents/BalanceTicker';

import ExchangeBar from '../newcomponents/exchangeBar';
import { useGlobalState } from '../..';






const DashboardMain = () => {

    const [projectsData, setProjectData] = useGlobalState("projectsData");
    const [selectedProject, setselectedProject] = useGlobalState("selectedProject");
    const [selectedExchange, setselectedExchange] = useGlobalState("selectedExchange");
    const [selectedPair, setselectedPair] = useGlobalState("selectedPair");
    
    const [pairID,setPairID] = useState(JSON.parse(projectsData[selectedProject].project_details).exchanges[selectedExchange].pairs[selectedPair].pair_id)
    useEffect(()=>{
        setPairID(JSON.parse(projectsData[selectedProject].project_details).exchanges[selectedExchange].pairs[selectedPair].pair_id);
    },[selectedProject,selectedExchange,selectedPair])
    return (
        <>
            <div className="row">
                <Tab.Container defaultActiveKey="bitcoin">
                    {/* <ExchangeBar/> */}
                    {/* <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body pt-3 pb-3">
                                <div className="coin-warpper d-flex align-items-center justify-content-between flex-wrap">
                                    <div style={{ width: "80%" }}>

                                        <Nav as="ul" className=" nav-pills">
                                            {tabHeading.map((item, index) => (
                                                <Nav.Item as="li" key={index}>
                                                    <Nav.Link as="button"
                                                        eventKey={item.keytype}
                                                        onClick={() => { setCurrentExchagne(item) }}
                                                    >
                                                        <span style={{ fontSize: "16px", fontStyle: "italic" }} >
                                                            {item.title}
                                                        </span>

                                                    </Nav.Link>
                                                </Nav.Item>
                                            ))}
                                        </Nav>

                                    </div>
                                    <div className="input-group custom-search-area w-auto">
                                        <Select
                                            defaultValue={selectoptions[0]}
                                            options={selectoptions}
                                            className="custom-react-select mb-xl-0 mb-3"
                                        />
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div> */}
                    <div className="col-xl-12">

                        <div className="row">
                            <div className="col-xl-12 col-xxl-12" >
                                <DashboardCoinChart  />
                            
                            </div>
                            <div className="col-xl-4 col-sm-12">
                                <CoinBuyOrderTable bgChange="warning" />
                               
                            </div>
                            <div className="col-xl-4 col-sm-12">
                                <CoinSellOrderTable bgChange="warning" />
                               
                            </div>
                            <div className="col-xl-4 col-sm-12">
                                <BalanceTicker data={pairID.split('/')[0]}/>
                                <BalanceTicker data={pairID.split('/')[1]}/>
                            </div>
                        </div>

                    </div>
                </Tab.Container>
            </div>
        </>
    )
}
export default DashboardMain;