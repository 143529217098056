import React, { useState, useEffect } from 'react';
import ReactApexChart from "react-apexcharts";

import "swiper/css";

import { useGlobalState } from '../..';
import { API_CALL } from '../../util';
import ReactLoading from 'react-loading';


const UPDATE_INTERVAL = 45000
const BalanceTicker2 = (props) => {
    const [projectsData, setProjectData] = useGlobalState("projectsData");
    const [selectedProject, setselectedProject] = useGlobalState("selectedProject");
    const [selectedExchange, setselectedExchange] = useGlobalState("selectedExchange");
    const [selectedPair, setselectedPair] = useGlobalState("selectedPair");

    const [pairHeading, setPairHeading] = useState({});


    const [isLoading, setIsLoading] = useState(true);


    const fetchUserBalance = async (data) => {

        let response = await API_CALL('https://taptash.com/api/v1/getbalance', {
            'application': 'LJkxrjhUo7ovfKy0UG5JLxQvlWRIdWwa',
            'uid': data.uid,
            "pid": data.pid
        }, {
            "exchange": data.exchange,
            "symbol": data.symbol.toUpperCase(),
            "side": "bids"
        })

        if (response.length !== 0) {
            setPairHeading(response);
            setIsLoading(false);
        }

    }

    useEffect(() => {
        if (!isLoading) {
            const interval = setInterval(() => {
                let { project_symbol, junction_user_id, project_details } = projectsData[selectedProject]
                project_details = JSON.parse(project_details);
                let exchange = project_details.exchanges[selectedExchange].name;
                let symbol = project_details.exchanges[selectedExchange].pairs[selectedPair].pair_id;

                let data = {
                    exchange: exchange,
                    symbol: symbol,
                    uid: junction_user_id,
                    pid: project_symbol
                }
                fetchUserBalance(data);
            }, UPDATE_INTERVAL);
            return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
        }
    }, [pairHeading])
    useEffect(() => {
        setIsLoading(true)
        let { project_symbol, junction_user_id, project_details } = projectsData[selectedProject]
        project_details = JSON.parse(project_details);
        let exchange = project_details.exchanges[selectedExchange].name;
        let symbol = project_details.exchanges[selectedExchange].pairs[selectedPair].pair_id;

        let data = {
            exchange: exchange,
            symbol: symbol,
            uid: junction_user_id,
            pid: project_symbol
        }

        fetchUserBalance(data);
    }, [selectedProject, selectedExchange, selectedPair])

    return (
        <div className="card">
            {
                isLoading ? <ReactLoading style={{
                    "position": "relative",
                    "left": "50%",
                    "transform": "translateX(-50%)",
                    "width": "30%"

                }} type={"bars"} color="#ffffff" height={150} width={50} /> :
                    <>
                        <div className="card-body d-flex align-items-center">

                            <div className="ms-3">
                                <h2 className="text-black fs-20 mb-0 font-w600">{props.data.toUpperCase()}</h2>
                                <span>{props.data.toUpperCase()} = {pairHeading[props.data.toUpperCase()].total !== undefined ? pairHeading[props.data.toUpperCase()].total : 0} </span>
                            </div>
                        </div>

                        <MMMainBalanceTicker color={props.color} />
                        <div className="card-footer">
                            <div className="row">
                                <div className="col text-center">
                                    <h5 className="font-weight-normal">{pairHeading[props.data.toUpperCase()].free !== undefined ? pairHeading[props.data.toUpperCase()].free : 0}</h5>
                                    <span>Free</span>
                                </div>
                                <div className="col text-center">
                                    <h5 className="font-weight-normal">{pairHeading[props.data.toUpperCase()].used !== undefined ? pairHeading[props.data.toUpperCase()].used : 0}</h5>
                                    <span>Used</span>
                                </div>
                                <div className="col text-center">
                                    <h5 className="font-weight-normal">{pairHeading[props.data.toUpperCase()].total !== undefined ? pairHeading[props.data.toUpperCase()].total : 0}</h5>
                                    <span>Total</span>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </div>
    )

}

export default BalanceTicker2;


class MMMainBalanceTicker extends React.Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            series: [
                {
                    name: 'Balance',
                    data: [
                        [1327359600000, 0],
                        [1327532400000, 0],
                        [1327878000000, 0],
                        [1328050800000, 0],
                        [1328223600000, 0],

                    ],
                },
            ],
            options: {
                chart: {
                    id: 'area-datetime',
                    height: 150,
                    type: "area",
                    width: '100%',
                    zoom: {
                        enabled: false
                    },
                    sparkline: {
                        enabled: true
                    },
                    toolbar: {
                        show: false
                    },
                },
                colors: [props.color],
                dataLabels: {
                    enabled: false
                },
                markers: {
                    size: 0,
                    style: 'hollow',
                },
                xaxis: {
                    show: false,
                    type: 'datetime',
                    labels: {
                        format: 'MMM',
                    },
                    axisBorder: {
                        show: false,
                    },
                },

                yaxis: {
                    show: false
                },
                grid: {
                    show: false,
                },
                responsive: [{
                    breakpoint: 1024,
                    options: {
                        chart: {
                            width: '100%',
                        }
                    }
                }],

            },
        };
    }

    render() {
        return (
            <div id="widget-chart1">
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="area"
                    height={150}
                />
            </div>
        );
    }
}