import React from 'react'



import lottieJson from './comingsoon.json'
import Lottie from 'react-lottie-player';

const Bots = () => {
    return (
        <div className='row'>
            <div className='col'>

                <Lottie
                    loop
                    animationData={lottieJson}
                    play
                    style={{margin:"auto",width:"30%"}}
                />

            </div>
        </div>
    )
}

export default Bots;