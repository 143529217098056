import React, { useContext, useEffect, useState } from 'react';
import { Tab, Nav, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DashboardCoinChart from '../newcomponents/CoinChart';
import CoinBuyOrderTable from '../newcomponents/CoinBuyOrderTable';
import CoinSellOrderTable from '../newcomponents/CoinSellOrderTable';
import BalanceTicker from '../newcomponents/BalanceTicker';
import Select from 'react-select';
import { useGlobalState } from '../..';


const selectoptions = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
];
const buyData = [
    { price: '82.3', amount: '0.15', total: '$134,12' },
    { price: '82.3', amount: '0.15', total: '$134,12' },
    { price: '82.3', amount: '0.15', total: '$134,12' },
    { price: '82.3', amount: '0.15', total: '$134,12' },
    { price: '82.3', amount: '0.15', total: '$134,12' },
    { price: '82.3', amount: '0.15', total: '$134,12' },
    { price: '82.3', amount: '0.15', total: '$134,12' },
    { price: '82.3', amount: '0.15', total: '$134,12' },
    { price: '82.3', amount: '0.15', total: '$134,12' },
    { price: '82.3', amount: '0.15', total: '$134,12' },
    { price: '82.3', amount: '0.15', total: '$134,12' },
    { price: '82.3', amount: '0.15', total: '$134,12' },
    { price: '82.3', amount: '0.15', total: '$134,12' },
    { price: '82.3', amount: '0.15', total: '$134,12' },
    { price: '82.3', amount: '0.15', total: '$134,12' },
    { price: '82.3', amount: '0.15', total: '$134,12' },
    { price: '82.3', amount: '0.15', total: '$134,12' },
    { price: '82.3', amount: '0.15', total: '$134,12' },
];


const ExchangeBar = ({  }) => {
   
    const [projectsData,setProjectData] = useGlobalState("projectsData");
    const [selectedProject, setselectedProject] = useGlobalState("selectedProject");
	const [selectedExchange, setselectedExchange] = useGlobalState("selectedExchange");
	const [selectedPair, setselectedPair] = useGlobalState("selectedPair");
   

    const [tabHeading,setTabHeading] = useState([]);
    const [pairHeading,setPairHeading] = useState([]); 

    const [isLoading,setIsLoading] = useState(true);
    useEffect(() => {
        setIsLoading(true);

        InitExchanges();
        InitPair();
        setselectedExchange(0)
        setselectedPair(0);
        setIsLoading(false);
       // setCurrentExchagne(tabHeading[2])
   

    },[selectedProject])
    useEffect(()=>{
        if(!isLoading){
          
            InitPair()
        }
    },[selectedExchange])

    useEffect(()=>{
        //console.log(selectedProject,selectedExchange,selectedPair)
    })

    const InitExchanges = ()=>{
        let heading = [];
        let projectDetails = JSON.parse(projectsData[selectedProject].project_details);
        let exchages = projectDetails.exchanges
        exchages.forEach(e => {
            heading.push({ title: e.name.toUpperCase(), keytype: e.name, changelog: e.name })
        });
        
        setTabHeading(heading);
    }

    const InitPair=()=>{
        let heading = [];
        let projectDetails = JSON.parse(projectsData[selectedProject].project_details);
        let myexchages = projectDetails.exchanges[selectedExchange];
        myexchages.pairs.forEach((e,i)=>{
            heading.push( { value: i, label: e.pair_id.toUpperCase() })
        })
        setselectedPair(0);
        setPairHeading(heading);
    
    }
    
    if(isLoading){
        return null
    }
    return (
        <>
            <div className="col-xl-12">
                <div className="card">
                    <div className="card-body pt-3 pb-3">
                        <div className="coin-warpper d-flex align-items-center justify-content-between flex-wrap">
                            <div style={{ width: "80%" }}>

                                <Nav as="ul" className="nav-pills"  >
                                    {tabHeading.map((item, index) => (
                                        <Nav.Item as="li" key={index}>
                                            <Nav.Link as="button"
                                                active= {tabHeading[selectedExchange].keytype === item.keytype }
                                                onClick={()=>{setselectedExchange(index)}}
                                            >
                                                <span style={{ fontSize: "16px", fontStyle: "italic" }} >
                                                    {item.title}
                                                </span>

                                            </Nav.Link>
                                        </Nav.Item>
                                    ))}
                                </Nav>

                            </div>
                            <div className="input-group custom-search-area w-auto">
                                <Select
                                    value={pairHeading[selectedPair]}
                                    options={pairHeading}
                                    className="custom-react-select mb-xl-0 mb-3"
                                    onChange={(e)=>setselectedPair(e.value)}
                                />
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
export default ExchangeBar;