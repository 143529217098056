import React, { useState, useEffect } from 'react';
import DigitalChartIndex4 from '../components/Dashboard/Index4/DigitalChartIndex4';
import BtcDigitalChartIndex4 from '../components/Dashboard/Index4/BtcDigitalChartIndex4';
import CoinChartIndex4 from '../components/Dashboard/Index4/CoinChartIndex4';
import ExchangeBar from '../newcomponents/exchangeBar';
import BalanceTicker2 from '../newcomponents/BalanceTicker2';

import { useGlobalState } from '../..';
import SpreadAnalysis from '../newcomponents/SpreadChart';
import MarketOrders from '../newcomponents/MarketOrders';
import Index2Slider from '../components/Dashboard/Index2/Index2Slider';
import SpreadSheet from '../newcomponents/SpreadSheet';


const MMAnalysis = () => {
    const [projectsData, setProjectData] = useGlobalState("projectsData");
    const [selectedProject, setselectedProject] = useGlobalState("selectedProject");
    const [selectedExchange, setselectedExchange] = useGlobalState("selectedExchange");
    const [selectedPair, setselectedPair] = useGlobalState("selectedPair");

    const [pairID, setPairID] = useState(JSON.parse(projectsData[selectedProject].project_details).exchanges[selectedExchange].pairs[selectedPair].pair_id)
    useEffect(() => {
        setPairID(JSON.parse(projectsData[selectedProject].project_details).exchanges[selectedExchange].pairs[selectedPair].pair_id);
    }, [selectedProject, selectedExchange, selectedPair])

    return (
        <>
            <div className="row">
                {/* <ExchangeBar/> */}
                <div className="col-xl-6 col-xxl-6">
                    <BalanceTicker2 data={pairID.split('/')[0]} color="#AC4CBC" />
                </div>
                <div className="col-xl-6 col-xxl-6">
                    <BalanceTicker2 data={pairID.split('/')[1]} color="#FFAB2D" />

                </div>


            </div>
            <div className='row'>
                <div className='col-xl-7 col-lg-12'>
                    <div className='row'>
                        <div className='col-12'>
                            <SpreadAnalysis />
                        </div>
                    </div>
                    <div className='row'>
                        <div className=' col-lg-6 col-md-6 col-sm-12'>
                            <Index2Slider data = {0}/>
                        </div>
                        <div className=' col-lg-6 col-md-6 col-sm-12'>
                            <Index2Slider  data = {1}/>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <Index2Slider data = {2}/>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <Index2Slider data = {3}/>
                        </div>
                    </div>




                    {/* <Index2Slider/> */}
                </div>
                <div className='col-xl-5 col-lg-12'>
                    <MarketOrders />
                </div>
            </div>

            <div className='row'>
                <SpreadSheet/>
            </div>
        </>
    )
}
export default MMAnalysis;