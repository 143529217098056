import React, { useState } from 'react';
import { useEffect } from 'react';
import MarketChart from '../components/Dashboard/Index2/MarketChart';
import ReactApexChart from "react-apexcharts";


const SpreadAnalysis = () => {
    return (
        <div className="row">
            <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                <div className="card market_chart">
                    <div className="card-header border-0 align-items-start flex-wrap pb-0">
                        <div>
                            <h2 className="heading">Spread Analysis</h2>
                            <div className="market-data">
                                <div className="income data">
                                    <span>This Month</span>
                                    <h4>$29.999.00</h4>
                                </div>
                                <div className="price data">
                                    <span>Price</span>
                                    <h4>480 <sub>- 0,5%</sub></h4>
                                </div>
                                <div className="rate data">
                                    <span>Rate</span>
                                    <h4>-0.0662%/hr</h4>
                                </div>
                                <div className="volume data">
                                    <span>volume</span>
                                    <h4>175k</h4>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="card-body custome-tooltip pt-0">
                        <div id="activity1"></div>
                        <SpreadChart/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SpreadAnalysis;

class SpreadChart extends React.Component {  
	constructor(props) {
		super(props);
		this.state = {
			series: [{
                name: "High",
                data: [1400, 800, 1200, 550, 1550, 600, 1250]
              },
              {
                name: "Low",
                data: [500, 600, 300, 400, 1200, 550, 1200]
              }
            ],
			options: {
				chart: {
					type: "area",
					height: 400,
                    group: 'social',
					toolbar: {
						show: false,
					},
				},
                zoom: {
                    enabled: false
                },
				plotOptions: {
                    // bar: {
                    //     horizontal: false,
                    //     endingShape:'rounded',
                    //     columnWidth: '35%',
                    //     borderRadius: 2,                    
                    // },
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show:false,
                  tooltipHoverFormatter: function(val, opts) {
                    return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
                  },
                  markers: {
                    fillColors:['green','red'],
                    width: 3,
                    height: 16,
                    strokeWidth: 0,
                    radius: 16
                  }
                },
                markers: {
                    size: [8,8],
                    strokeWidth: [4,8],
                    strokeColors: ['#fff','#fff'],
                    border:4,
                    radius: 4,
                    colors:['green','red','red'],
                    hover: {
                      size: 10,
                    }
                },
                xaxis: {
                    categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                    labels: {
                     style: {
                        colors: '#3E4954',
                        fontSize: '14px',
                         fontFamily: 'Poppins',
                        fontWeight: 100,
                        
                      },
                    },
                    axisBorder:{
                        show: false,
                    }
                },
                yaxis: {
                    labels: {
                        show: true,
                        align: 'right',
                        minWidth: 15,
                        offsetX:-16,
                        style: {
                          colors: '#666666',
                          fontSize: '14px',
                           fontFamily: 'Poppins',
                          fontWeight: 100,
                          
                        },
                    },
                },
                fill: {
                    colors:['green','red'],
                    type:'gradient',
                    opacity: 1,
                    gradient: {
                        shade:'light',
                        shadeIntensity: 1,
                        colorStops: [ 
                          [
                            {
                              offset: 0,
                              color: 'green',
                              opacity: 0.4
                            },
                            {
                              offset: 0.6,
                              color: 'green',
                              opacity: 0.25
                            },
                            {
                              offset: 100,
                              color: 'green',
                              opacity: 0
                            }
                          ],
                          [
                            {
                              offset: 0,
                              color: 'red',
                              opacity: .4
                            },
                            {
                              offset: 50,
                              color: 'red',
                              opacity: 0.25
                            },
                            {
                              offset: 100,
                              color: 'red',
                              opacity: 0
                            }
                          ]
                        ]
        
                  },
                },
                colors:['var(--secondary)','var(--primary)'],
                stroke:{
                    curve : "straight",
                     width: 3,
                },
                grid: {
                    borderColor: '#e1dede',
                    strokeDashArray:8,
                    
                      xaxis: {
                          lines: {
                          show: true,
                          opacity: 0.5,
                          }
                      },
                      yaxis: {
                          lines: {
                          show: true,
                          opacity: 0.5,
                          }
                      },
                      row: {
                          colors: undefined,
                          opacity: 0.5
                      },  
                      column: {
                          colors: undefined,
                          opacity: 0.5
                      },  
                 },
                 responsive: [{
                      breakpoint: 1602,
                      options: {
                          markers: {
                               size: [6,6,4],
                               hover: {
                                  size: 7,
                                }
                          },chart: {
                          height: 230,
                      },	
                      },
                      
                 }]
                    
			}, 
		};
	}

  
	render() {
        return (
            <div id="activity1">
                <ReactApexChart options={this.state.options} series={this.state.series} type="area" height={400}  />
            </div>
        );
	}
}