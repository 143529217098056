import React, { useEffect,useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useGlobalState } from '../..';
import { API_CALL } from '../../util';
import ReactLoading from 'react-loading';



const UPDATE_INTERVAL = 45000
const CoinSellOrderTable = ({ bgChange }) => {
    const [projectsData, setProjectData] = useGlobalState("projectsData");
    const [selectedProject, setselectedProject] = useGlobalState("selectedProject");
    const [selectedExchange, setselectedExchange] = useGlobalState("selectedExchange");
    const [selectedPair, setselectedPair] = useGlobalState("selectedPair");

    const [pairHeading, setPairHeading] = useState([]);
    const [total, setTotal] = useState({ average: 0, totalCoin: 0, totalAmount: 0 })

    const [isLoading, setIsLoading] = useState(true);

    const fetchCoinData = async (data) => {

        let response = await API_CALL('https://taptash.com3/api/v1/getorderbook', {
            'application': 'LJkxrjhUo7ovfKy0UG5JLxQvlWRIdWwa',
            'uid': data.uid,
            "pid": data.pid
        }, {
            "exchange": data.exchange,
            "symbol": data.symbol.toUpperCase(),
            "side": "asks"
        })

        
        let d = []
        let t = {a:0,b:0,c:0}
        response.forEach(e=>{
            let obj = { price: e[0], amount: e[1], total: parseFloat(e[0]*e[1]).toFixed(2) }
            d.push(obj);
            t.a+=obj.price; t.b+=obj.amount; t.c+=(t.a * t.b);
        })
        setPairHeading(d);
        setTotal({
            average:(t.a/d.length).toFixed(2),
            totalCoin:t.b.toFixed(2),
            totalAmount:((t.a/d.length).toFixed(2) * t.b.toFixed(2)).toFixed(2)
        })
        setIsLoading(false);
    

    }

    useEffect(() => {
        if (!isLoading) {
            const interval = setInterval(() => {
                let { project_symbol, junction_user_id, project_details } = projectsData[selectedProject]
                project_details = JSON.parse(project_details);
                let exchange = project_details.exchanges[selectedExchange].name;
                let symbol = project_details.exchanges[selectedExchange].pairs[selectedPair].pair_id;

                let data = {
                    exchange: exchange,
                    symbol: symbol,
                    uid: junction_user_id,
                    pid: project_symbol
                }
                fetchCoinData(data);
            }, UPDATE_INTERVAL);
            return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
        }
    }, [pairHeading])
    useEffect(() => {
        setIsLoading(true)
        let { project_symbol, junction_user_id, project_details } = projectsData[selectedProject]
        project_details = JSON.parse(project_details);
        let exchange = project_details.exchanges[selectedExchange].name;
        let symbol = project_details.exchanges[selectedExchange].pairs[selectedPair].pair_id;

        let data = {
            exchange: exchange,
            symbol: symbol,
            uid: junction_user_id,
            pid: project_symbol
        }

        fetchCoinData(data);
    }, [selectedProject, selectedExchange, selectedPair])


    return (
        <>

            <div className="card price-list">
                <div className="card-header border-0 pb-2">
                    <div className="chart-title">
                        <h4 className={`mb-0 `} style={{ color: "red" }}>Sell Orders</h4>
                    </div>

                </div>
                <div className=" text-center border-0">
                    <hr />
                </div>
                <div className="card-body p-3 py-0">
                    <div className="table-responsive" style={{ overflowY: 'scroll', maxHeight: "350px" }}>
                        <table className={`table text-center order-tbl bg-${bgChange}-hover`}>
                            <thead>
                                <tr>
                                    <th className="text-left">Price</th>
                                    <th className="text-center">Amount</th>
                                    <th className="text-right">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ?
                                    <tr>
                                        <td>
                                        </td>
                                        <td><ReactLoading style={{
                                            "position": "relative",
                                            "left": "50%",
                                            "transform": "translateX(-50%)",
                                            "width": "100%"
                                        }} type={"spin"} color={"black"} height={150} width={50} /></td>
                                    </tr>
                                    : pairHeading.map((item, index) => {


                                        return (
                                            <tr key={index}>
                                                <td className="text-left">{item.price}</td>
                                                <td>{item.amount}</td>
                                                <td className="text-right">{item.total}</td>
                                            </tr>
                                        )
                                    }
                                    )}

                            </tbody>
                        </table>
                    </div>
                </div>
                <div className=" text-center py-1 border-0">
                    <hr />
                </div>
                { !isLoading &&
                <table className={`table text-center order-tbl bg-${bgChange}-hover`}>
                    <thead>
                        <tr>
                            <th className="text-left" style={{ fontWeight: "bold" }}>Average Price</th>
                            <th className="text-center" style={{ fontWeight: "bold" }}>Total Amount</th>
                            <th className="text-right" style={{ fontWeight: "bold" }}>Total in $</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr >
                            <td className="text-left">{total.average}</td>
                            <td>{total.totalCoin}</td>
                            <td className="text-right">{total.totalAmount}</td>
                        </tr>


                    </tbody>
                </table>
            }

            </div>
        </>
    )
}
export default CoinSellOrderTable;