import React, { useState } from 'react';
import { useEffect } from 'react';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import Select from 'react-select';
import axios from 'axios';
import { useGlobalState } from '../..';
import { API_CALL } from '../../util';
import ReactLoading from 'react-loading';
import MarketChart from '../components/Dashboard/Index2/MarketChart';
import BalanceCardSlider from '../components/Dashboard/Dashboard/BalanceCardSlider';
import CoinChart from '../components/Crypto/Coin/CoinChart';
import BitCoinChart from '../components/Crypto/Coin/BitCoinChart';
import KlineData from './Klinedata';


let klineData = {
    series: [
        {
            name: "New Clients",
            data: [
                
            ],
            stroke: {
              width: 15
            }
        },   
    ],
      options: {
        chart: {
            type: 'candlestick',
            height: 340,
            toolbar: {
                show: false
            },
           			
        },
      
        
        plotOptions: {
            candlestick: {
                colors: {
                    upward: '#3ab67a',
                    downward: '#fd5353'
                }
            }
        },
        title: {
            text: '',
            align: 'left'
        },
        xaxis: {
            type: 'datetime'
        },
        yaxis: {
            opposite: true, 
            tooltip: {
                enabled: true
            }
        }

      },
};

const UPDATE_INTERVAL = 30000
const DashboardCoinChart = (exchange, symbol) => {
    const [projectsData, setProjectData] = useGlobalState("projectsData");
    const [selectedProject, setselectedProject] = useGlobalState("selectedProject");
    const [selectedExchange, setselectedExchange] = useGlobalState("selectedExchange");
    const [selectedPair, setselectedPair] = useGlobalState("selectedPair");


    const [data, setData] = useState(null);

    const [candleStick,setCandleStick] = useState(klineData) 


    useEffect(() => {
        if (data !== null) {
            const interval = setInterval(() => {
                let { project_symbol, junction_user_id, project_details } = projectsData[selectedProject]
                project_details = JSON.parse(project_details);
                let exchange = project_details.exchanges[selectedExchange].name;
                let symbol = project_details.exchanges[selectedExchange].pairs[selectedPair].pair_id;

                let data = {
                    exchange: exchange,
                    symbol: symbol,
                    uid: junction_user_id,
                    pid: project_symbol
                }
                fetchCoinData(data);
            }, UPDATE_INTERVAL);
            return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
        }
    }, [data])

    const fetchCoinData = async (data) => {

        let response = await API_CALL('https://taptash.com/api/v1/getpairdata', {
            'application': 'LJkxrjhUo7ovfKy0UG5JLxQvlWRIdWwa',
            'uid': data.uid,
            "pid": data.pid
        }, {
            "exchange": data.exchange,
            "symbol": data.symbol.toUpperCase()
        })
        let kline = await API_CALL('https://taptash.com/api/v1/getchartdata', {
            'application': 'LJkxrjhUo7ovfKy0UG5JLxQvlWRIdWwa',
            'uid': data.uid,
            "pid": data.pid
        }, {
            "exchange": data.exchange,
            "symbol": data.symbol.toUpperCase(),
            "timeframe":"1h",
            "limit":1000
        })
       

        let newKlineData = {...klineData};
        newKlineData.series[0].name = data.symbol.toUpperCase()
        newKlineData.series[0].data =[]
        kline.forEach(e=>{
            newKlineData.series[0].data.push({
                x: e[0],
                y: [e[1],e[1], e[4], e[4]]
              })
        })
        
       
        setCandleStick(newKlineData);
      
        setData({
            price: parseFloat(response.average).toFixed(1),
            BuyBid: parseFloat(response.ask).toFixed(1),
            SellBid: parseFloat(response.bid).toFixed(1),
            Change24H: (response.change / 100).toFixed(2),
            Change24HVolume: parseFloat(response.quoteVolume).toFixed(1),
            MarketCap: parseFloat(response.baseVolume).toFixed(1)
        });


    }
    useEffect(() => {
        setData(null)
        let { project_symbol, junction_user_id, project_details } = projectsData[selectedProject]
        project_details = JSON.parse(project_details);
        let exchange = project_details.exchanges[selectedExchange].name;
        let symbol = project_details.exchanges[selectedExchange].pairs[selectedPair].pair_id;

        let data = {
            exchange: exchange,
            symbol: symbol,
            uid: junction_user_id,
            pid: project_symbol
        }

        fetchCoinData(data);
    }, [selectedExchange, selectedPair, selectedProject])
    //fetchCoinData();


    return (
        <>
            <div className="card coin-content">
                <div className="card-header border-0 flex-wrap">
                    <div className="mb-2">
                        <h4 className="heading m-0">{projectsData[selectedProject].project_name}</h4>
                        <span className="fs-16">{projectsData[selectedProject].project_description}</span>
                    </div>

                </div>


                <div className="card-body">
                    {
                        data === null

                            ?
                            <div className="d-block text-center">
                                <ReactLoading style={{
                                    "position": "relative",
                                    "left": "50%",
                                    "transform": "translateX(-50%)",
                                    "width": "100px"
                                }} type={"bars"} color={"black"} height={50} width={50} />
                            </div>
                            :
                            <>
                                {/* <MarketChart/> */}
                                <div className="d-flex align-items-center justify-content-between flex-wrap">

                                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                                        <div className="price-content">
                                            <span className="fs-18 d-block mb-2">Price</span>
                                            <h4 className="fs-20 font-w600">${data.price}</h4>
                                        </div>
                                        <div className="price-content">
                                            <span className="fs-14 d-block mb-2">24h% change</span>
                                            <h4 className={`font-w600 text-${data.Change24H < 0 ? "danger" : "success"}`}>{data.Change24H}<i className={`fa-solid fa-caret-${data.Change24H < 0 ? "down" : "up"} ms-1 text-${data.Change24H < 0 ? "danger" : "success"}`}></i></h4>
                                        </div>
                                        <div className="price-content">
                                            <span className="fs-14 d-block mb-2">Volume (24h)</span>
                                            <h4 className="font-w600">$ {data.Change24HVolume}</h4>
                                        </div>
                                        <div className="price-content">
                                            <span className="fs-14 d-block mb-2">Token Volume (24H)</span>
                                            <h4 className="font-w600">{data.MarketCap}</h4>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <h4 className="me-5 font-w600 mb-0"><span className="text-success me-2">BUY</span> ${data.BuyBid}</h4>
                                        <h4 className="font-w600 mb-0"><span className="text-danger me-2">SELL</span> ${data.SellBid} </h4>
                                    </div>
                                </div>
                                <KlineData data = {candleStick}/>
                            </>
                    }
                    {/* <div id="bitcoinhChart"></div> */}
                    {/* <BitCoinChart /> */}
                </div>

            </div>
        </>
    )
}
export default DashboardCoinChart;