import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
//import {NavLink} from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Dropdown, Nav, Tab } from 'react-bootstrap';
const listData = [
    {}, {}, {},
    {}, {}, {},
    {}, {}, {},
    {}, {}, {}, {}, {}, {},{},{},{},{},{},{},{}
];
const MarketOrders = () => {

    return (
        <div className="row">
            {/* */}
            <div className="col">

                <div className="card">
                    <div className="card-header py-2">
                        <h2 className="heading">Order Book <span style={{color:"black"}}>(BTC/USDT)</span></h2>
                    </div>
                    <div className="card-body pt-0 pb-3 px-2">
                        <Tab.Container defaultActiveKey="Orderbook">
                            <nav className="buy-sell style-1">
                                <Nav className=" nav-tabs" id="nav-tab1" role="tablist">
                                    <Nav.Link as="button" className="nav-link " eventKey="Orderbook" type="button" style={{ fontSize: "12px" }}>Order Book</Nav.Link>
                                    <Nav.Link as="button" className="nav-link" eventKey="Openorders" type="button" style={{ fontSize: "12px" }} >Open Orders</Nav.Link>
                                    <Nav.Link as="button" className="nav-link" eventKey="Orderhistory" type="button" style={{ fontSize: "12px" }}>Order History</Nav.Link>

                                </Nav>
                            </nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="Orderbook" >
                                    <div className="list-row-head">
                                        <span>Price</span>
                                        <span>Size</span>
                                        <span className="text-end">Total</span>
                                    </div>
                                    <div className="list-table danger" style={{ overflowY: 'scroll', height: "360px" }}>
                                        {listData.map((data, i) => (
                                            <div className="list-row" key={i}>
                                                <span style={{ color: "red" }}>19852.63</span>
                                                <span>0.050300</span>
                                                <span className="text-end">2.362877</span>
                                                <div className="bg-layer"></div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="list-bottom-info">
                                        <h3 className="text-center text-danger mb-0">19858.19 </h3>
                                    </div>
                                    <div className="list-table" style={{ overflowY: 'scroll', height: "360px" }}>
                                        {listData.map((data, i) => (
                                            <div className="list-row " key={i}>
                                                <span style={{ color: "green" }} >19852.63</span>
                                                <span>0.050300</span>
                                                <span className="text-end">2.362877</span>
                                                <div className="bg-layer"></div>
                                            </div>
                                        ))}
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="Openorders" >
                                    <div className="list-row-head">
                                        <span>Price</span>
                                        <span>Size</span>
                                        <span className="text-end">Total</span>
                                    </div>
                                    <div className="list-table danger" style={{ overflowY: 'scroll', height: "360px" }}>
                                        {listData.map((data, i) => (
                                            <div className="list-row" key={i}>
                                                <span style={{ color: "red" }}>19852.63</span>
                                                <span>0.050300</span>
                                                <span className="text-end">2.362877</span>
                                                <div className="bg-layer"></div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="list-bottom-info">
                                        <h3 className="text-center text-danger mb-0">19858.19 </h3>
                                    </div>
                                    <div className="list-table" style={{ overflowY: 'scroll', maxHeight: "360px" }}>
                                        {listData.map((data, i) => (
                                            <div className="list-row " key={i}>
                                                <span style={{ color: "green" }} >19852.63</span>
                                                <span>0.050300</span>
                                                <span className="text-end">2.362877</span>
                                                <div className="bg-layer"></div>
                                            </div>
                                        ))}
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="Orderhistory" >
                                    <div className="list-row-head">
                                        <span>Price</span>
                                        <span>Size</span>
                                        <span className="text-end">Total</span>
                                    </div>
                                    <div className="list-table danger" style={{ overflowY: 'scroll', maxHeight: "360px" }}>
                                        {listData.map((data, i) => (
                                            <div className="list-row" key={i}>
                                                <span style={{ color: "red" }}>19852.63</span>
                                                <span>0.050300</span>
                                                <span className="text-end">2.362877</span>
                                                <div className="bg-layer"></div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="list-bottom-info">
                                        <h3 className="text-center text-danger mb-0">19858.19 </h3>
                                    </div>
                                    <div className="list-table" style={{ overflowY: 'scroll', maxHeight: "360px" }}>
                                        {listData.map((data, i) => (
                                            <div className="list-row " key={i}>
                                                <span style={{ color: "green" }} >19852.63</span>
                                                <span>0.050300</span>
                                                <span className="text-end">2.362877</span>
                                                <div className="bg-layer"></div>
                                            </div>
                                        ))}
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>

        </div>
    )
}


export default MarketOrders;