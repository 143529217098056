import React from "react";

import ReactApexChart from "react-apexcharts";




import { useState, useRef, useEffect } from 'react';
import { Tab, Nav, Row, Col, Card } from 'react-bootstrap';

import Lottie from 'react-lottie-player'
import AllOrder from '../components/Report/History/AllOrder';
import { Link } from 'react-router-dom';
const VolumeBooster = () => {
    return (
        <>
            <div className="row">
                <div className="col-xxl-12">
                    <div className="card">
                        <Tab.Container defaultActiveKey="All">
                            <div className="card-header border-0 pb-2 flex-wrap">
                                <h4 className="heading me-2">Volume History</h4>


                            </div>
                            <div className="card-body pt-2">
                                <Tab.Content >


                                    <VolumeHistory />
                                  
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </>
    )
}

const tableData = [
    { title: 'BTC/Bitcoin' },
    { title: 'ETH/Ethereum' },
    { title: 'BNB/BNB' },
    { title: 'XRP/XRP' },
    { title: 'DOGE/Dogecoin' },
    { title: 'DOT/Polkadot' },
    { title: 'TRX/TRON' },
    { title: 'LTC/Litecoin' },
    { title: 'SOL/Solana' },
    { title: 'UNI/Uniswap' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
    { title: 'AVAX/Avalanche' },
];

const VolumeHistory = () => {
    const [data, setData] = useState(
        document.querySelectorAll("#history_wrapper tbody tr")
    );
    const sort = 25;
    const activePag = useRef(0);
    const [test, settest] = useState(0);

    // Active data
    const chageData = (frist, sec) => {
        for (var i = 0; i < data.length; ++i) {
            if (i >= frist && i < sec) {
                data[i].classList.remove("d-none");
            } else {
                data[i].classList.add("d-none");
            }
        }
    };
    // use effect
    useEffect(() => {
        setData(document.querySelectorAll("#history_wrapper tbody tr"));
        //chackboxFun();
    }, [test]);


    // Active pagginarion
    activePag.current === 0 && chageData(0, sort);
    // paggination
    let paggination = Array(Math.ceil(data.length / sort))
        .fill()
        .map((_, i) => i + 1);

    // Active paggination & chage data
    const onClick = (i) => {
        activePag.current = i;
        chageData(activePag.current * sort, (activePag.current + 1) * sort);
        settest(i);
    };
    return (
        <>
            <div className='row'>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                    <div className={`card coin-card bg-success`}>

                        <div className="card-body p-4 text-center">
                            <div className="title">
                                <h4>Total Trades</h4>
                            </div>
                            <div className="chart-num">
                                <h2>155</h2>
                            </div>
                        </div>
                    </div>
                </div>



                <div className='col-lg-3 col-md-6 col-sm-12'>
                    <div className={`card coin-card bg-dark`}>

                        <div className="card-body p-4 text-center">
                            <div className="title">
                                <h4>Total Volume Boosted</h4>
                            </div>
                            <div className="chart-num">
                                <h2>155$</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-lg-3 col-md-6 col-sm-12'>
                    <div className={`card coin-card bg-info`}>

                        <div className="card-body p-4 text-center">
                            <div className="title">
                                <h4>24H Volume</h4>
                            </div>
                            <div className="chart-num">
                                <h2>155</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-lg-3 col-md-6 col-sm-12'>
                    <div className={`card coin-card bg-danger`}>

                        <div className="card-body p-4 text-center">
                            <div className="title">
                                <h4>Total Fee</h4>
                            </div>
                            <div className="chart-num">
                                <h2>155</h2>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <Row>
                <Col xl={12}>
                    <Card>
                        <Card.Header>
                            <h4 className="card-title">Daily Volume</h4>
                        </Card.Header>
                        <Card.Body>
                            <VolumeHistoryVolumeBooster />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <div id="history_wrapper" className="table-responsive dataTablehistory">
                <div className="dataTables_wrapper no-footer">
                    <table id="example" className="table shadow-hover dataTable display" style={{ minWidth: "845px" }}>
                        <thead>
                            <tr className="text-center">
                                <th>Date</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Tokens (Coin)</th>
                                <th>Total (USDT)</th>
                                <th>Price</th>
                                <th>Value in USD</th>
                                <th>Profit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((item, index) => (
                                <tr key={index}>
                                    <td className="text-center">2022-10-03 16:24</td>
                                    <td className="text-center">{item.title}</td>
                                    <td className="text-center">Buy</td>
                                    <td className="text-center">Limit</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">100.00</td>
                                    <td className="text-center">576.76</td>
                                    <td className="text-center">
                                        22
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                        <div className="dataTables_info">
                            Showing {activePag.current * sort + 1} to{" "}
                            {data.length > (activePag.current + 1) * sort
                                ? (activePag.current + 1) * sort
                                : data.length}{" "}
                            of {data.length} entries
                        </div>
                        <div
                            className="dataTables_paginate paging_simple_numbers mb-0"
                            id="application-tbl1_paginate"
                        >

                            <Link
                                className="paginate_button previous "

                                onClick={() => {
                                    activePag.current > 0 &&
                                        onClick(activePag.current - 1)
                                    window.scrollTo(0, 0)
                                }
                                }
                            >
                                <i className="fa fa-angle-double-left" ></i>
                            </Link>
                            <span>
                                {paggination.map((number, i) => (
                                    <Link
                                        key={i}

                                        className={`paginate_button  ${activePag.current === i ? "current" : ""
                                            } `}

                                        onClick={() => { onClick(i); window.scrollTo(0, 0) }}
                                    >
                                        {number}
                                    </Link>
                                ))}
                            </span>

                            <Link
                                className="paginate_button next"


                                onClick={() => {
                                    activePag.current + 1 < paggination.length &&
                                        onClick(activePag.current + 1)
                                    window.scrollTo(0, 0)
                                }
                                }
                            >
                                <i className="fa fa-angle-double-right" ></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default VolumeBooster;

class VolumeHistoryVolumeBooster extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            series: [
                {
                    name: "V.History",
                    data: [80, 40, 55, 20, 45, 30, 80, 90, 85, 90, 30, 34],
                },
            ],
            options: {
                chart: {
                    type: "bar",
                    height: 230,
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        dataLabels: {
                            position: "top",
                        },
                    },
                },
                colors: ["#9568ff"],
                legend: {
                    show: false,
                    position: "top",
                    horizontalAlign: "left",
                },
                dataLabels: {
                    enabled: false,
                    offsetX: -6,
                    style: {
                        fontSize: "12px",
                        // colors: ["#fff"],
                    },
                },
                stroke: {
                    show: false,
                },
                yaxis: {
                    lines: {
                        show: false,
                    },
                },
                xaxis: {
                    show: false,
                    categories: [2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
                },
            },
        };
    }

    render() {
        return (
            <div id="chart" className="line-chart-style bar-chart">
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="bar"
                    height={300}
                />
            </div>
        );
    }
}
