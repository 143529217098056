import React, { useState, useEffect } from 'react';
import ReactApexChart from "react-apexcharts";

import "swiper/css";

import { useGlobalState } from '../..';
import { API_CALL } from '../../util';
import ReactLoading from 'react-loading';


const UPDATE_INTERVAL = 45000
const BalanceTicker = (props) => {
    const [projectsData, setProjectData] = useGlobalState("projectsData");
    const [selectedProject, setselectedProject] = useGlobalState("selectedProject");
    const [selectedExchange, setselectedExchange] = useGlobalState("selectedExchange");
    const [selectedPair, setselectedPair] = useGlobalState("selectedPair");

    const [pairHeading, setPairHeading] = useState({});
  

    const [isLoading, setIsLoading] = useState(true);

    
    const fetchUserBalance = async (data) => {

        let response = await API_CALL('https://taptash.com/api/v1/getbalance', {
            'application': 'LJkxrjhUo7ovfKy0UG5JLxQvlWRIdWwa',
            'uid': "1VTkJBukxAgq1Y6avYjCR0HPvxl1",
            "pid": "btca"
        }, {
            "exchange": data.exchange
        })


        console.log(response);
  
        if(response.length !== 0){
            setPairHeading(response);
            setIsLoading(false);
        }
            
       


    }

    useEffect(() => {
        if (!isLoading) {
            const interval = setInterval(() => {
                let { project_symbol, junction_user_id, project_details } = projectsData[selectedProject]
                project_details = JSON.parse(project_details);
                let exchange = project_details.exchanges[selectedExchange].name;
                let symbol = project_details.exchanges[selectedExchange].pairs[selectedPair].pair_id;

                let data = {
                    exchange: exchange,
                    symbol: symbol,
                    uid: junction_user_id,
                    pid: project_symbol
                }
                fetchUserBalance(data);
            }, UPDATE_INTERVAL);
            return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
        }
    }, [pairHeading])
    useEffect(() => {
        setIsLoading(true)
        let { project_symbol, junction_user_id, project_details } = projectsData[selectedProject]
        project_details = JSON.parse(project_details);
        let exchange = project_details.exchanges[selectedExchange].name;
        let symbol = project_details.exchanges[selectedExchange].pairs[selectedPair].pair_id;

        let data = {
            exchange: exchange,
            symbol: symbol,
            uid: junction_user_id,
            pid: project_symbol
        }

        fetchUserBalance(data);
    }, [selectedProject, selectedExchange, selectedPair])

    return (
        <div className="col-12" >
            <div className={`card card-box ${isLoading?'bg-warning':"bg-secondary"}`}>
                {
                    isLoading ? <ReactLoading style={{
                        "position": "relative",
                        "left": "50%",
                        "transform": "translateX(-50%)",
                        "width": "30%"
                        
                    }} type={"bars"} color="#ffffff" height={150} width={50} /> : <> <div className="card-header border-0 pb-0">
                        <div className="chart-num-days">
                            <p>
                                <i className="fa-solid fa-sort-up me-2"></i>
                                {props.data.toUpperCase()} Balance
                            </p>
                            <h2 className="count-num text-white">{pairHeading[props.data.toUpperCase()].total!==undefined?pairHeading[props.data.toUpperCase()].total:0} <span style={{fontSize:"12px"}}>{props.data}</span> </h2> 
                        </div>

                    </div>
                        <div className="card-body p-0 custome-tooltip">
                            {/* <div id="widgetChart3" className="chart-primary"></div> */}
                            <TickerWidgetMain />

                        </div></>
                }

            </div>
        </div>
    )

}

export default BalanceTicker;


class TickerWidgetMain extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            series: [
                {
                    name: 'Balance',
                    data: [
                        [213,1],
                        [343,2],
                        [54,3],
                        [565,4],
                        [343,5],
                    ],
                },
            ],
            options: {
                chart: {
                    height: 70,
                    type: "line",
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false
                    },
                    sparkline: {
                        enabled: true
                    }
                },
                colors: ['#0E8A74'],
                dataLabels: {
                    enabled: false,
                },

                legend: {
                    show: false,
                },
                stroke: {
                    show: true,
                    width: 6,
                    curve: 'smooth',
                    colors: ['rgba(255, 255, 255, 0.5)'],
                },
                grid: {
                    show: false,
                    borderColor: '#eee',
                    padding: {
                        top: 10,
                        right: 0,
                        bottom: 20,
                        left: 0

                    }
                },
                states: {
                    normal: {
                        filter: {
                            type: 'none',
                            value: 0
                        }
                    },
                    hover: {
                        filter: {
                            type: 'none',
                            value: 0
                        }
                    },
                    active: {
                        allowMultipleDataPointsSelection: false,
                        filter: {
                            type: 'none',
                            value: 0
                        }
                    }
                },
                xaxis: {
                   
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false
                    },
                    labels: {
                        show: false,
                        style: {
                            fontSize: '12px',
                        }
                    },
                    crosshairs: {
                        show: false,
                        position: 'front',
                        stroke: {
                            width: 1,
                            dashArray: 3
                        }
                    },
                    tooltip: {
                        enabled: false,
                        formatter: undefined,
                        offsetY: 0,
                        style: {
                            fontSize: '12px',
                        }
                    }
                },
                yaxis: {
                    show: false,
                },
                fill: {
                    opacity: 1,
                    colors: '#FB3E7A'
                },
                tooltip: {
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Poppins',
                    },
                    y: {
                        formatter: function (val) {
                            return val
                        }
                    }
                }

            },
        };
    }

    render() {
        return (
            <div id="widgetChart3" className="chart-primary">
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="line"
                    height={70}
                />
            </div>
        );
    }
}