import React, { useContext, useEffect, useState } from 'react';
import { Tab, Nav, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DashboardCoinChart from '../newcomponents/CoinChart';
import CoinBuyOrderTable from '../newcomponents/CoinBuyOrderTable';
import CoinSellOrderTable from '../newcomponents/CoinSellOrderTable';
import BalanceTicker from '../newcomponents/BalanceTicker';
import Select from 'react-select';
import { useGlobalState } from '../..';

const dailyReport = [
    {name:'up', average_price:0,total_orders:0,our_orders:0, other_orders:0,total_value_tokens:0,total_value_usd:0, change:'100%'},
    {name:'up', average_price:0,total_orders:0,our_orders:0, other_orders:0,total_value_tokens:0,total_value_usd:0, change:'50%'},
    {name:'up', average_price:0,total_orders:0,our_orders:0, other_orders:0,total_value_tokens:0,total_value_usd:0, change:'25%'},
    {name:'up', average_price:0,total_orders:0,our_orders:0, other_orders:0,total_value_tokens:0,total_value_usd:0, change:'10%' },
    {name:'up', average_price:0,total_orders:0,our_orders:0, other_orders:0,total_value_tokens:0,total_value_usd:0, change:'5%'},
    {name:'up', average_price:0,total_orders:0,our_orders:0, other_orders:0,total_value_tokens:0,total_value_usd:0, change:'2%'},
    {name:'down', average_price:0,total_orders:0,our_orders:0, other_orders:0,total_value_tokens:0,total_value_usd:0, change:'-2%'},
    {name:'down', average_price:0,total_orders:0,our_orders:0, other_orders:0,total_value_tokens:0,total_value_usd:0, change:'-5%'},
    {name:'down', average_price:0,total_orders:0,our_orders:0, other_orders:0,total_value_tokens:0,total_value_usd:0, change:'-10%'},
    {name:'down', average_price:0,total_orders:0,our_orders:0, other_orders:0,total_value_tokens:0,total_value_usd:0, change:'-25%'},
    {name:'down', average_price:0,total_orders:0,our_orders:0, other_orders:0,total_value_tokens:0,total_value_usd:0, change:'-50%'},
    {name:'down', average_price:0,total_orders:0,our_orders:0, other_orders:0,total_value_tokens:0,total_value_usd:0, change:'-100%'},
];


const SpreadSheet = ()=>{
    return(
        <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="heading mb-0">Depth Analysis</h4>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table shadow-hover table-bordered tbl-report">
                                    <thead>
                                        <tr>
                                            <th className="text-end">Average Price</th>
                                            <th className="text-end">Total Number of Orders</th>
                                            <th className="text-end">Our Orders</th>
                                            <th className="text-end">Other Orders</th>
                                            <th className="text-end">Total Value in Token (Excluding Ours)</th>
                                            <th className="text-end">Total Value in USDT (Excluding Ours)</th>
                                            <th className="text-end">Change</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dailyReport.map((item, ind)=>(
                                            <tr key={ind}>
                                               
                                                <td className="text-center"><span>$</span> {item.average_price}</td>
                                                <td className="text-center"> {item.total_orders}</td>
                                                <td className="text-center"> {item.our_orders}</td>
                                                <td className="text-center">{item.other_orders}</td>
                                                <td className="text-center"> {item.total_value_tokens}</td>
                                                <td className="text-center"><span>$</span> {item.total_value_usd}</td>
                                                <td className="text-center"><span className={`label ${item.name==="down" ? "label-danger" : "label-success"}`}>{item.change}</span></td>
                                            </tr>
                                        ))}                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
    )
}


export default SpreadSheet;