import React from "react";
import ReactApexChart from "react-apexcharts";

class KlineData extends React.Component {
  constructor(props) {
    super(props);

    this.state = props.data;
  }
  componentWillReceiveProps(nextProps) {
   // console.log('componentWillReceiveProps', nextProps);
    this.setState(nextProps.data);
    
}
  render() {
    return (
      <div id="bitcoinhChart">
        <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="candlestick"
            height={340}
        />
      </div>
    );
  }
}

export default KlineData;