import React, { useState } from 'react';
import { TabContainer, TabContent, TabPane ,Card , Row,Tab,Col,Nav} from 'react-bootstrap';
//import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';


const inputBlog = [
    { label: 'Name', value: 'John' },
    { label: 'Surname', value: 'Brahim' },
    { label: 'Specialty', value: 'Developer' },
    { label: 'Skills', value: 'HTML,  JavaScript,  PHP' },
];

const options2 = [
    { value: '1', label: 'Select' },
    { value: '2', label: 'Male' },
    { value: '3', label: 'Female' },
    { value: '4', label: 'Other' }
]
const options3 = [
    { value: '1', label: 'Russia' },
    { value: '2', label: 'Canada' },
    { value: '3', label: 'China' },
    { value: '4', label: 'India' }
]
const options4 = [
    { value: '1', label: 'Krasnodar' },
    { value: '2', label: 'Tyumen' },
    { value: '3', label: 'Chelyabinsk' },
    { value: '4', label: 'Moscow' }
]
const tabData = [
    {
      name: "General Settings",
      icon: "home",
      content:
        "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove.",
    },
    {
      name: "Market Maker Settings",
      icon: "user",
      content:
        "Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor.      ",
    },
    {
      name: "Arbitrage Settings",
      icon: "phone",
      content:
        "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove.",
    },

    {
      name: "Volume Booster Settings",
      icon: "envelope",
      content:
        "Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor.      ",
    },
  ];
const Settings = () => {
    // const [selectOption , setSelectOption] = useState('Gender');
    return (
        <>
            <div className="row">

                <div className="col-12">
                   
                            <Col xl={12}>
                                <Card>
                                  
                                    <Card.Body>
                                        <Row>
                                            <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                                                <Col sm={3}>
                                                    <Nav as="ul" className="flex-column nav-pills mb-3">
                                                        {tabData.map((data, i) => (
                                                            <Nav.Item as="li" style={{fontSize:"14px"}} key={i}>
                                                                <Nav.Link eventKey={data.name.toLowerCase()}>
                                                                    {data.name}
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        ))}
                                                    </Nav>
                                                </Col>
                                                <Col sm={9}>
                                                    <Tab.Content className="ms-2">
                                                        {tabData.map((data, i) => (
                                                            <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                                                                <p>
                                                                    {data.content} {data.content}
                                                                </p>
                                                            </Tab.Pane>
                                                        ))}
                                                    </Tab.Content>{" "}
                                                </Col>
                                            </Tab.Container>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </div>
                   
            </div>
        </>
    )
}
export default Settings;