import React from 'react';
import {Link} from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

//import { data } from '../../pages/WidgetBasic/ActiveUser';
//import WidgetChartIndex3 from './Index3/WidgetChartIndex3';
//import StockChartIndex3 from './Index3/StockChartIndex3';


const WidgetChartIndex3 = loadable(() =>
	pMinDelay(import("./Index3/WidgetChartIndex3"), 1000)
);
const StockChartIndex3 = loadable(() =>
	pMinDelay(import("./Index3/StockChartIndex3"), 1000)
);


const widgetChart = [
    {id:1, price:'$65,123', bgcolor:'bg-warning'},
    {id:2, price:'$75,542',bgcolor:'bg-secondary'},
    {id:3, price:'$40,742', bgcolor:'bg-pink'},
    {id:4, price:'$71,321', bgcolor:'bg-primary'},
];

const tableData = [
    {number: '55542545', title: '1.48586 BTC', time: '5m', status: 'Confirmed', statusColor:'label-success'},
    {number: '65432187', title: '2.12389 BTC', time: '4m', status: 'Unconfirmed', statusColor:'label-warning'},
    {number: '78954123', title: '3.65412 BTC', time: '6m', status: 'Canceled', statusColor:'label-danger'},
    {number: '55542545', title: '1.48586 BTC', time: '5m', status: 'Confirmed', statusColor:'label-success'},
    {number: '65432187', title: '2.12389 BTC', time: '4m', status: 'Unconfirmed', statusColor:'label-warning'},
    {number: '78954123', title: '3.65412 BTC', time: '6m', status: 'Canceled', statusColor:'label-danger'},
    {number: '55542545', title: '1.48586 BTC', time: '5m', status: 'Confirmed', statusColor:'label-success'},
    {number: '65432187', title: '2.12389 BTC', time: '4m', status: 'Unconfirmed', statusColor:'label-warning'},
    {number: '78954123', title: '3.65412 BTC', time: '6m', status: 'Canceled', statusColor:'label-danger'},
];

const Dashboard3 = () =>{
    return(
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="row">
                        {widgetChart.map((item, ind)=>(
                            <div className="col-xl-3 col-lg-6 col-sm-6" key={ind}>
                                <div className={`card card-box bg-secondary`}>
                                    <div className="card-header border-0 pb-0">
                                        <div className="chart-num-days">
                                            <p>
                                                <i className="fa-solid fa-sort-down me-2"></i>
                                                4%(30 days)
                                            </p>
                                            <h2 className="count-num text-white">{item.price}</h2>
                                        </div>
                                   
                                    </div>
                                    <div className="card-body p-0 custome-tooltip">
                                        {/* <div id="widgetChart3" className="chart-primary"></div> */}
                                        <WidgetChartIndex3 />
                                    </div>
                                </div>
                            </div>
                        ))}
                        
                    </div>
                </div>
                <div className="col-xl-8">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex align-items-center">
                                        <i className="fa-brands fa-bitcoin scale-2 text-warning"></i>
                                        <h4 className="heading ms-3 mb-0">BTCUSD Perennial</h4>
                                    </div>	
                                </div>
                                <div className="card-body pt-2"> 
                                    <div className="volume-list">
                                        <div>
                                            <h5>$9,125.00 USD</h5>
                                            <span className="text-danger">-12.04 <i className="fa-sharp fa-solid fa-chevron-down"></i></span>
                                        </div>
                                        <div>
                                            <span className="text-success">High</span>
                                            <h5>9,124.00 USD</h5>
                                        </div>	
                                        <div>
                                            <span className="text-danger">Low</span>
                                            <h5>9,124.00 USD</h5>
                                        </div>
                                        <div>
                                            <span className="text-success">24H Volume</span>
                                            <h5>2,124 USD</h5>
                                        </div>	
                                    </div>
                                    {/* <div id="btcStock"></div> */}
                                    <StockChartIndex3 />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="heading mb-0">Activity</h4>
                        </div>
                        <div className="card-body pt-0">
                            <div className="table-responsive">
                                <table className="table border-0 mb-0 activity-tbl">
                                    <thead>					
                                        <tr className="bg-pale-dark">
                                            <th>#</th>
                                            <th className="text-center">BTC</th>
                                            <th>Time</th>
                                            <th className="text-end">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((item, ind)=>(
                                            <tr key={ind}>
                                                <td>
                                                    <Link to={"#"} className="text-primary">{item.number}</Link>
                                                </td>
                                                <td className="text-nowrap">{item.title}</td>
                                                <td>
                                                    <span className="timeago text-nowrap">{item.time} ago</span>
                                                </td>
                                                <td className="text-end"><span className={`label ${item.statusColor}`}>{item.status}</span></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>                        

             </div>   
        </>
    )
}
export default Dashboard3;